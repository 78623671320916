<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  //components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Incluir Banco',
      banco:{
        banco_nome : null,
        banco_codigo: null,
        banco_digito : null,
        banco_maxnossoNum :null,
        banco_orientacoes : null
      }
    }
  },
  validations() {
    return {
      banco:{
        banco_nome : {required},
        banco_codigo: {required},
      }
    }
  },
  
  mounted() {
      this.setNewTitle();
  },
  methods: {
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit('doPost', this.banco);
    },
  },
    
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Banco</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">Nome</label>
                  <div class="col">
                    <input v-model="banco.banco_nome" class="form-control text-leftt" type="text" placeholder="digite o Nome do banco" id="banco">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_codigo" class="col-form-label">Codigo</label>
                  <div class="col">
                    <input  v-model="banco.banco_codigo" class="form-control text-left" type="text" placeholder="digite o codigo do banco" id="codigo">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_digito" class="col-form-label">Digito</label>
                  <div class="col">
                    <input  v-model="banco.banco_digito" class="form-control text-left" type="text" placeholder="digite o digito" id="digito">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_num" class="col-form-label">Nosso numero</label>
                  <div class="col">
                    <input  v-model="banco.banco_maxnossoNum" class="form-control text-left" type="text" placeholder="digite o Numero maximo" id="num">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_orient" class="col-form-label">Orientações</label>
                  <div class="col">
                    <input  v-model="banco.banco_orientacoes" class="form-control text-left" type="text" placeholder="Orientações" id="orient">
                  </div>
                </div>
              </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>